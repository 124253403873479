import React, { Suspense } from 'react'
import { Link } from 'gatsby'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { AccountAddressForm } from '/src/components/user'
import { TabsList } from '/src/components/common'
import { useAddress, useCart, useUser } from '/src/hooks'
import HeroImage from '/src/components/layout/HeroImage'

const UserAddressesPage = () => {
  const user = useUser()
  // const addresses = useAddress()
  const cart = useCart()

  if (!user) {
    return <></>
  }

  return (
    <Suspense fallback={<></>}>
      <Layout hero={<HeroImage title="Addresses" />}>
        <Row className="d-flex justify-content-center">
          <Col md={10} lg={10}>
            <Row className="mt-2 mb-4">
              <Col sm={6}>
                <div className="page-title">
                  <h1 className="title">My Account</h1>
                </div>
              </Col>
              <Col sm={6} className="d-flex justify-content-end">
                <Stack direction="horizontal" gap={4}>
                  <Link className="button outline" to="/user/logout">
                    Logout
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </Stack>
              </Col>
            </Row>
            <Row>
              <Col className="mb-4" sm={12}>
                <TabsList items={[
                    {
                      label: 'Account',
                      path: '/user'
                    },
                    {
                      label: 'Orders',
                      path: '/user/orders'
                    },
                    {
                      label: 'Enquiries',
                      path: '/user/enquiries'
                    },
                    {
                      label: 'Addresses',
                      path: '/user/addresses'
                    }
                  ]} />
              </Col>
            </Row>
            <AccountAddressForm user={user} cart={cart} />
          </Col>
        </Row>
      </Layout>
    </Suspense>
  )
}

export default UserAddressesPage

export const Head = () => <title>My Addresses | Metalcorp</title>
